import React from "react";
import loadable from "@loadable/component";
const ImageLoader = loadable(() => import("../common/ImageLoader"));
import { motion } from "framer-motion";

import milaIntroImg from "assets/images/landingAir/mila-intro/hi-mila-black.png";
import milaIntroImgWEBP from "assets/images/landingAir/mila-intro/hi-mila-black.png?as=webp";

/**
 * @returns {JSX.Element}
 * @constructor
 */

const MilaIntro = () => {
  return (
    <section className="mila-intro">
      <div className="container">
        <motion.h2
          className="mila-intro__title"
          initial={{ opacity: 0, y: "10%" }}
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0 }}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: "10%" },
          }}
        >
          Say hello to
          <strong>Mila</strong>
        </motion.h2>
        <ImageLoader
          className="mila-intro__image img-relative"
          placeholderAspectRatio={745 / 757}
          image={milaIntroImg}
          webp={milaIntroImgWEBP}
          alt={"Say hi to Mila"}
        />
        <div className="mila-intro__content">
          <motion.h3
            className="mila-intro__subtitle"
            initial={{ opacity: 0, y: 20 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
          >
            Your partner for a cleaner, healthier home.
          </motion.h3>
          <motion.p
            className="mila-intro__text-highlight"
            initial={{ opacity: 0, y: 20 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.01 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
          >
           Let’s face it, today’s air purifiers are outdated appliances wrapped in sketchy marketing. <br /> Mila is different.
          </motion.p>
          {/* <motion.p
            className="mila-intro__text"
            initial={{ opacity: 0, y: 20 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.02 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
          >
            Dust, dander, chemical fumes, seasonal allergens, smoke, stinky
            odors… Mila takes no prisoners. And the best part? You can see the
            work for yourself.
          </motion.p> */}
        </div>
      </div>
    </section>
  );
};

export default MilaIntro;
